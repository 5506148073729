import React from "react";

function AttentionBadge({ children, color = "red" }) {
  return (
    <span
      className={`font-bold text-xs uppercase ml-2 py-1 px-2 bg-${color}-600 text-white rounded-lg`}
      style={{ fontSize: "50%" }}
    >
      {children}
    </span>
  );
}

export default AttentionBadge;
