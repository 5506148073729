import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import map from "lodash/map";
import get from "lodash/get";
import find from "lodash/find";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import CourseDuration from "../course/CourseDuration";
import CourseDates from "../course/CourseDates";
import CoursePrice from "../course/CoursePrice";
import AttentionBadge from "../AttentionBadge";

function CoursesByCategory({ categoryName }) {
  const query = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fields: { collection: { eq: "courses" } } }
        sort: { fields: [frontmatter___title], order: ASC }
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              popular
              new
              basics
              advanced
              courseId
              path
              title
              lecturer
              categories
              price
              durationDays
              courseDates {
                startDate
                endDate
                place
              }
              date(formatString: "DD MMMM, YYYY")
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `);

  const edges = filter(get(query, "allMarkdownRemark.edges"), ({ node }) => {
    const categories = get(node, "frontmatter.categories");
    return find(categories, cat => toLower(cat) === toLower(categoryName));
  });

  return (
    <div>
      <h2 className="highlight-title mb-5 mt-10 pb-1">{categoryName}</h2>
      {map(edges, ({ node }) => (
        <div key={node.id} className="mb-5">
          <Link
            to={node.frontmatter.path}
            className="font-bold flex-1 text-indigo-800 text-xl no-underline flex items-center"
          >
            {node.frontmatter.title}
            {node.frontmatter.popular ? (
              <AttentionBadge>Suosittu</AttentionBadge>
            ) : null}
            {node.frontmatter.new ? (
              <AttentionBadge>Uusi</AttentionBadge>
            ) : null}
            {node.frontmatter.advanced ? (
              <AttentionBadge color="blue">Jatko</AttentionBadge>
            ) : null}
            {node.frontmatter.basics ? (
              <AttentionBadge color="green">Perusteet</AttentionBadge>
            ) : null}
          </Link>
          <div className="sm:flex">
            <div className="flex sm:flex-2 justify-between flex-wrap">
              <div className="flex-1">
                <CourseDates courseDates={node.frontmatter.courseDates} />
              </div>
              <div className="flex-1 flex justify-start flex-col items-end sm:block">
                <CourseDuration durationDays={node.frontmatter.durationDays} />
              </div>
              <div className="flex-1 hidden sm:flex justify-start flex-col">
                <CoursePrice price={node.frontmatter.price} />
              </div>
            </div>
            <div className="hidden mt-2 sm:mt-0 sm:justify-end items-center sm:flex">
              <Link to={node.frontmatter.path} className="button">
                Lue lisää
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CoursesByCategory;
